import { Link } from "react-router-dom"

interface NavbarElement {
    itemName: string,
    to: string
}

export default function NavBar({
    navigationItems = []
}: {
    navigationItems: NavbarElement[]
}) {
    return (
        <ul className = "navbar" style = {{
            position: 'fixed',
            top: 0,
            right: 0,
            width: '100vw'
        }}>
            {
                navigationItems.map(({itemName, to}, i) => 

                <li key = {i} className = "navbarElement">
                    <Link to = {{pathname: to}}>{itemName}</Link>
                </li>)
            }
        </ul>
    )
}