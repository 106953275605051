import { ReactNode } from "react";
import posts from "../posts";
import Section from "../components/Section";
import { Link } from "react-router-dom";

export default function ThoughtsPage() {
    return (
        <Section
        >
            <div>
            <h1>Blog</h1>
            <p className="text" >An archive of the random thoughts I've had throughout the years.</p>
                <div style = {{
                display: 'flex',
                flexDirection: 'column',
                gap: '1em'
            }}>
                    {
                        posts.map(post => {
                            return (
                                <Link to={`${post.postId}`} style = {{textDecoration: 'none', color: '#F2F1F2'}}>
                                    <div style = {{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '1em',
                                        backgroundColor: '#2f302f',
                                        borderRadius: '1.5em',
                                        padding: '1.5em',
                                        width: '100%'
                                    }}
                                    className = "buttonAnimation"
                                    >
                                        <h3 style = {{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: '1.25em'
                                        }}>{post.title}</h3>
                                        <p>{post.description}</p>
                                    </div>
                                </Link>
                                
                            )
                        })
                    }
                </div>
                
            </div>
            


        </Section>
    )
}