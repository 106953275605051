import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import { ContactPage, GitHub, LinkedIn, Mail } from "@mui/icons-material";

export default function Root() {
    return (
        <>
            <NavBar navigationItems={[
                {
                    itemName: "About",
                    to: "/about"
                },
                {
                    itemName: "Experience",
                    to: "/experience"
                },
                {
                    itemName: "Skills",
                    to: "/skills"
                },
                {
                    itemName: "Projects",
                    to: "/projects"
                },
                {
                    itemName: "Blog",
                    to: "/blog"
                }
            ]}/>
            <main
            style = {{
                paddingTop: '2em'
            }}
            >
            <Outlet/>
            </main>
            <footer style = {{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    padding: '2em',
                    boxSizing: 'border-box',
                    gap: '1em',
                    justifyContent: 'flex-end',
                    width: '100%'

                }}>
                    <LinkedIn
                    onClick = {() => {
                        window.open("https://www.linkedin.com/in/jhuang705/")
                    }}
                    style = {{
                        cursor: 'pointer'
                    }}
                    className = "linkhover"
                    />
                    <GitHub
                    style = {{
                        cursor: 'pointer'
        
                    }}
                    onClick = {
                        () => {
                            window.open("https://github.com/jhuang38")
                        }
                    }
                    className = "linkhover"
                    />
                    <Mail
                    style = {{
                        cursor: 'pointer'
                    }}
                    onClick = {
                        () => {
                            window.open('mailto:j528huan@uwaterloo.ca')
                        }
                    }
                    className = "linkhover"
                    />
                    <ContactPage style = {{
                        cursor: 'pointer'

                    }}
                    onClick = {
                        () => {
                            window.open("https://drive.google.com/file/d/1s5p8reTkaxCboC2816AwpT9wSkOj16Ly/view?usp=sharing")
                        }
                    }
                    className = "linkhover"
                    />

                    
                </footer>
            
            
            
            
            
        </>
    )
}