import Section from "../components/Section";
import rollingCatGif from '../resources/catroll.gif'
import { motion } from "framer-motion";

export default function About() {
    return (
        <>
            <Section
            style = {{height: '100%'}}
            >
                <div>
                    <h1>Hi, I'm James!</h1>
                    <p >
                            I'm a third year Computer Science student studying at the University of Waterloo, 
                            though I was a Computer Engineering student until Spring 2023.
                            My main interest in terms of software development is in back-end development, but I'm always open to learning new things! At the moment,
                            I'm looking more into AI.
                            <br/> <br/>
                            When I'm not programming, you can find me playing video games or reading manga. I've also been trying to go outside more frequently in 
                            recent years. Feel free to have a look around!
                        </p>
                    <motion.img src = {rollingCatGif} style = {{
                        marginTop: '6em',
                        minWidth: '30%',
                        maxWidth: '20em',
                        display: 'block',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        cursor: 'grab'
                    }}
                    alt='cat'
                    drag
                    dragConstraints={{
                        top: -50,
                        right: 50,
                        bottom: 50, 
                        left: -50
                    }}
                    whileTap={{ cursor: "grabbing" }}
                    />
                    
                </div>
                

            </Section>
        </>
    )
}