import Section from "../components/Section"
import ImageList from "../components/ImageList";

import jsLogo from '../resources/jslogo.png'
import tsLogo from '../resources/tslogo.png'
import pythonLogo from '../resources/pythonlogo.png'
import cppLogo from '../resources/cpplogo.png'
import csharpLogo from '../resources/csharplogo.png'
import javaLogo from '../resources/javaLogo.png'
import cLogo from '../resources/clogo.png'
import htmlLogo from '../resources/htmllogo.png'
import cssLogo from '../resources/csslogo.png'

import nodejsLogo from '../resources/nodejslogo.png'
import flaskLogo from '../resources/flasklogo.png'
import expressLogo from '../resources/expresslogo.png'
import dotnetLogo from '../resources/dotnetlogo.png'

import reactLogo from '../resources/reactlogo.png'
import reduxLogo from '../resources/reduxLogo.svg'
import sqlalchemyLogo from '../resources/sqlalchemylogo.png'
import socketioLogo from '../resources/socketiologo.png'

import gitlogo from '../resources/gitlogo.png'
import dockerLogo from '../resources/dockerlogo.png'
import postgresLogo from '../resources/postgreslogo.png'
import mongodbLogo from '../resources/mongodblogo.png'
import jenkinsLogo from '../resources/jenkinslogo.png'
import firebaseLogo from '../resources/firebaselogo.png'

const languageSources = [
    jsLogo, //js
    tsLogo, // ts
    pythonLogo, // python
    cppLogo, // c++
    csharpLogo, // c#
    javaLogo,
    cLogo, // c
    htmlLogo, // html
    cssLogo // css
]

const frameworkSources = [
    nodejsLogo,
    flaskLogo,
    expressLogo,
    dotnetLogo

]

const librariesSources = [
    reactLogo,
    reduxLogo,
    sqlalchemyLogo,
    socketioLogo
]

const miscSources = [
    gitlogo,
    dockerLogo,
    postgresLogo,
    mongodbLogo,
    jenkinsLogo,
    firebaseLogo
]

export default function SkillsPage() {
    return (
        <Section>
            <div>
                <h1>Skills</h1>
                <p>A couple of the languages I've worked with...</p>
                    <ImageList images={languageSources}/>
                <br/>

                <p>Frameworks too!</p>
                <ImageList images = {frameworkSources}/> <br/>

                <p>Some libraries as well...</p>
                <ImageList images = {librariesSources}/> <br/>

                <p>Finally, some other technologies I've used!</p>
                <ImageList images = {miscSources}/> 
            </div>
        </Section>
        
    )
}