import { ReactNode } from "react"
import { motion } from "framer-motion"


export default function Section({
    title,
    style = {},
    children = <></>
}: {
    title?: String,
    style?: Object,
    children?: ReactNode
}) {
    return (
        <motion.div className="pageSection" style = {style}
        initial = {{
            opacity: 0
        }}
        animate = {{
            opacity: 1
        }}
        >
            {title && <h2 className = "text heading">{title}</h2>}
            <section style = {{height: '100%'}}>
                {children}
            </section>
        </motion.div>
        
    
        
    )
}