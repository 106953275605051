
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from "@mui/lab";
import Section from "../components/Section";
import paypalLogo from '../resources/paypalLogo.png'
import bmoLogo from '../resources/bmologo.jpg'
import uwaterlooLogo from '../resources/uwaterlooLogo.png'

interface ExperienceItem {
    title: string,
    company: string,
    startDate: string,
    endDate: string,
    description: string,
    imageSource: string
}

const experienceItems: Array<ExperienceItem> = [
    {
        title: "Software Engineering Intern",
        company: "PayPal",
        startDate: "May 2024",
        endDate: "August 2024",
        description: "I worked with the Hyperwallet Payouts team within funding, which is the team responsible for features for allowing clients to move funds into the Hyperwallet system. I worked on projects to streamline the investigations and workflows of internal teams.",
        imageSource: paypalLogo
    },
    {
        title: "Software Developer Intern",
        company: "BMO Capital Markets",
        startDate: "May 2023",
        endDate: "August 2023",
        description: "In my second term at BMO Capital Markets, I mostly focused on building backend APIs to drive applications around the trade floor using JavaScript (Node.js), Python, and C#.",
        imageSource: bmoLogo
    },
    {
        title: "Software Developer Co-op",
        company: "BMO Capital Markets",
        startDate: "September 2022",
        endDate: "December 2022",
        description: "For my first term at BMO Capital Markets, I was responsible for several full-stack project refactors and revamps. I mainly worked with JavaScript, React.js, Python, and Node.js.",
        imageSource: bmoLogo,
    },
    {
        title: "Course Technical Support Assistant",
        company: "University of Waterloo",
        startDate: "January 2022",
        endDate: "April 2022",
        description: "During my first co-op term, I did a mixture of software development and data analysis for the faculty of Math at UWaterloo.",
        imageSource: uwaterlooLogo
    }
];

export default function ExperiencePage()  {
    return (
        <Section style = {{
            height: '100%'
        }}>
            <div>
                <h1>Experience</h1>
                <p>Places I've worked over the years.</p>
                <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                    },
                }}
                >
                    {
                        experienceItems.map(experience => {
                            return (
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <img src = {experience.imageSource}
                                        style = {{
                                            maxHeight: '3em',
                                            padding: '1em',
                                            borderRadius: '1.25em'
                                        }}
                                        alt={experience.company}
                                        />
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <div style = {{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%'
                                        }}>

                                        <h3 style = {{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: '1.25em'
                                        }}>{experience.title}</h3>
                                        <em className = "text s ">{experience.company}</em>
                                        <em className ="text">{experience.startDate} - {experience.endDate}</em>
                                        <div style = {{
                                            display: 'flex',
                                            gap: '0.5em',
                                            alignContent: 'center'
                                        }}>
                                            
                                        
                                        </div>
                                        
                                        <p className="text">{experience.description}</p>
                                        </div>
                                        
                                    </TimelineContent>
                                </TimelineItem>
                            )
                        })
                    }
                </Timeline>
            </div>
            
        </Section>
    )
}