import { ReactNode } from "react";
import Section from "../components/Section";
import { useParams, useSearchParams } from "react-router-dom";
import posts from "../posts";

export default function BlogPostPage() {
    const params = useParams() as {
        blogid: string
    };
    const postData = posts.find(item => item.postId === Number(params.blogid));
    
    return (<>
            {
                postData?.content
            }
    </>)
}