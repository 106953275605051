export default function ImageList({images}: {images: Array<string>}) {
    return <div
    style={{
        display: 'flex',
        gap: '1em',
        justifyContent: 'center',
        alignItems: 'center'
    }}
    >
        {
            images.map(image => {
                return <img src = {image} height='85em' alt = "skill" style = {{
                    borderRadius: '0.5em',
                }}/>
            })
        }
    </div>
}