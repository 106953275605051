import { ReactNode } from "react"
import engtocs from "./resources/engtocs.png"
import Section from "./components/Section";
import engSched from './resources/2a_sched.png'
import csSched from './resources/2b_sched.png'
import gojoNo from './resources/no.jpg'

import p3Menu from './resources/p3_blogpost/menu.png'
import p3Gameplay1 from './resources/p3_blogpost/gameplay1.png'
import p3Gameplay2 from './resources/p3_blogpost/gameplay2.png'
import akihikoAoa from './resources/p3_blogpost/akihiko_aoa.png'
import armageddonTheurgy from './resources/p3_blogpost/armageddon_theurgy.png'
import partyTierList from './resources/p3_blogpost/party_tier_list.png'
import aigisImage from './resources/p3_blogpost/aigis.jpg'

interface Post {
    postId: number,
    title: string,
    description: string,
    content: ReactNode,
}


const posts: Array<Post> = [
    // add course review for 2B
    {
        postId: 4,
        title: "3A CS Course Review",
        description: "Reflecting on the courses I took in 3A.",
        content: <Section>
            <h1>3A CS Course Review/Retrospective</h1>
            <p style = {{
                margin: 0
            }}>
                I guess I'll be continuing to write term reviews. Now that all the marks for Winter 2024 are out, here are some of my thoughts on the courses I took in 3A.
            </p>
            <ul style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}>
                    <li>
                        <em>
                            CS 240 - Data Structures & Data Management
                        </em>:
                        The first half of this course dealt with concepts like runtime analysis (asymptotic analysis, average/expected runtime), while the second
                        half covered various data structures and algorithms such as skip lists, tries, and string matching/compression. Overall, I think this course was 
                        ok. The main means of assesment aside from exams were assignments and programming assignments. I generally found the assignments weren't terribly difficult,
                        as many of the solutions became clear after putting in some amount of thought. I really didn't appreciate how some of the assignments were marked, as some of
                        the marks taken often felt arbitrary. While the programming, though completing a progamming and written assignment at the same time did get stressful at times.
                        The exams were hoenstly pretty reasonable, but the class average was still in the 60-70s. I found the tutorials relatively useful, though I think my experience
                        with this area varied since I switched sections halfway through the game. I also went to quite a few hours, which were especially useful for understanding some
                        of the more complex topics.

                    </li>
                    <li>
                        <em>
                            CS 348 - Intro to Database Management
                        </em>: I wasn't a big fan of this course. A lot of the content taught felt fairly impractical for the typical database work you would expect in a dev role.
                        For example, a big section of this course was dedicated to disccusing concurrency within a database, which would be mostly useless unless you are directly working on
                        implementing a database. As well, several concepts were poorly explained and/or inconsistent. Cost estimation was probably the worst of this issue, as the answers
                        given for assignments was completely different than the ones I came up with, but I still received full marks for my work. This made explaining work significantly
                        more difficult. The structure of assesents didn't help with understanding the content, as since they were so short and spread over 2 weeks, I would often just forget that the course existed
                        for 2 weeks and finish the assignment a day before the due date. They also decided to make the final exam ridiculously difficult for some reason, including questions
                        that were effectively impossible to study via the given slides and textbook. I'm honestly just glad this course is over.
                    </li>
                    <li>
                        <em>
                            Math 239 - Intro to Combinatorics
                        </em>: I enjoyed this course quite a lot! The first half of the course focused on enumeration, which covered some interesting ways to count particular objects. I think
                        the most interesting part of this course was learning about the concept of a weight function combined with a generating series. Understanding the content for enumeration
                        mainly just came down to understanding what a weight function actually was and doing a ton of practice. The second half of the course covering graph theory wasn't too bad either,
                        as it was relatively straightforward most of the time. The assignments often required a fair amount of time and often demanded a lot more critical thinking compared to the assignments I 
                        did in Engineering. They were also annoying in how they were only worth 15 marks each, so even small mistakes would take a good amount off your mark. The assesments were generally 
                        a lot more straightforward compared to the assignments, though I'm thankful for the scaling nonetheless (it somehow boosted my mark to something physically impossible, as my assignment
                        score wasn't high enough). I found the tutorials quite helpful for understanding the content, as since our sections were pretty empty, there was a lot of time for the TA to explain 
                        the tutorial questions in depth and clarify the course concepts.
                    </li>
                    <li>
                        <em>
                            STAT 231 - Statistics
                        </em>: Pretty "meh" course overall. Most of the content was pretty straightforward, as it was just basic calculus, application/derivation of formulas, and interpreting graphs. 
                            The lectures were pretty dry, but our prof explained things relatively well for the most part. The assesments for this course came in the form of occasional quizzes, which 
                            just reused some questions for the textbook. As a result, they were all extremely straightforward. In terms of exams, the two midterms were extremely easy, but the final was 
                            significantly more challenging than anything else in the course. In fact, there was a discord chat created for complaining to the VPA of MathSoc about the difficulty of the exam.
                            Overall, this course wasn't particularly memorable and I will not be thinking about it again anytime soon.
                    </li>
                    <li>
                        <em>
                            ENGL 119 - Communications in Mathematics and Computer Science
                        </em>: This course was a mixed bag for me. On one hand, it was really interesting to see all the applications of commmunication in technical fields. As well, the in-class activities were
                        pretty fun. Our prof was also really good. On the other hand, I hated the assignments in this course with a burning passion. One of these assignments involved creating a research report for an arbitrary 
                        topic in Math/CS. I don't even know why this was an assesment, as I feel that research reports are rather rare in the technical field unless you are specifically aiming to do research. Even in this case,
                        the project was framed as a recommendation report, so completing this project probably wouldn't even be helpful in that case. Not only did you need to write a 1500+ word document for the research report 
                        (I got PTSD flashbacks to my IAs and my EE in IB :/), prior to the report, an additional "pitch proposal" and "progress report presentation" were required for the project, which took up several weeks in the term. Another gripe I had with the assignments
                        was with how they were marked. While the given rubrics seemed reasonable, it often seemed impossible to get anything above the 80s, even after thoroughly reviewing my writing. This wasn't unique to me,
                        as several of the people I asked also felt this way. These assignments were part of what made this term feel like a massive drag, which is unfortunate since some of the courses I had this term had really interesting
                        content. While I feel like I learned a couple new things from this course, I ultimately found myself wondering throughout the term whether taking this course was a mistake. Even with the benefit of hindsight,
                        I still don't know if ENGL 119 was actually worth it.
                    </li>

            </ul>
            <p>
                The term felt a lot more exhausting compared to previous ones. I think this was due to two main factors. Firstly, as I alluded in my thoughts on several of 
                the courses this term, a lot of the content in my courses was pretty dry, so I didn't exactly enjoy myself in class or studying as much. As well, this was my first double 
                study term, so doing eight consecutive months of school didn't help with staying motivated. I had a lot more to say about the semester immediately after classes ended, but 
                now that marks are out, big parts of the term just felt like a blur. Overall, this term had its moments, but most of the time it just felt like a massive chore.
            </p>
        </Section>
    },
    {
        postId: 3,
        title: "A review of Persona 3 Reload",
        description: "My thoughts on the re-release of Persona 3.",
        content: <Section>
            <h1>A Review of Persona 3 Reload</h1>
            <p style = {{
                margin: 0
            }}>
                <em>Warning: This post contains spoilers for Persona 3 Reload.
                    If you want to experience the game yourself, you should probably not read this.

                </em>
            </p>
            <p>
              Persona 3 Reload is a remaster/remake of games from the Persona 3 series. Overall, I would say that it's definitely better
              than P3 FES, which I played via an emulator. I can't really speak for how it measures up to the original or Portable, but 
              it is worth noting that some content has been excluded from each (i.e. no FemC from Portable, no Answer from FES). While some people
              seem really bothered by this, I didn't really care.
            </p>
            <h2>Visuals</h2>
            <p style = {{
                margin: 0
            }}>
                The visuals of this game are superb. Just to illustrate one example, here's the menu.
                <div style = {{
                display: 'flex',
                gap: '1em',
                justifyContent: 'center',
                padding: '1em'
                
                }}
                className = "screenShrink"
                >
                    <img src = {p3Menu} alt = "p3 menu" style = {{
                        maxWidth: '90%'
                    }}/>

                </div>
                In addition to the menus, the actual game environemnts are really nice.
                <div style = {{
                display: 'flex',
                gap: '1em',
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em',
                
                }}
                className = "screenShrink"
                >
                    <img src = {p3Gameplay1} alt = "p3 gameplay" style = {{
                        maxWidth: '90%'
                    }}/>
                    <img src = {p3Gameplay2} alt = "p3 gameplay" style = {{
                        maxWidth: '90%'
                    }}/>

                </div>
                I generally prefer these visuals to those of P5, mainly because of my preference for aesthetics. The models look really nice, with the entire
                game just feeling super polished.
            </p>
            <h2>
                Gameplay
            </h2>
            <p style = {{
                margin: 0
            }}>
                The gameplay in Reload is also really nice. In terms of changes compared to the originals, they made lots of 
                changes with the fusion mechanics to match P5. As well, they basically added the P5 mechanics of baton tossing and direct 
                commands in, which makes the game more fun in my opinion (not that the original's Tactics system was terrible, I just prefer
                having full control of the party). Finally, while not a gameplay update (as this existed in the original Persona 3 games), the
                all-out-attack mechanic now has a finishing screen like that in Persona 5, which adds to the cool factor this game brings to the table.
            </p>
            <div style = {{
                display: 'flex',
                gap: '1em',
                justifyContent: 'center',
                padding: '1em'
                
                }}
                className = "screenShrink"
                >
                    <img src = {akihikoAoa} alt = "Akihiko's All out Attack finisher." style = {{
                        maxWidth: '90%'
                    }}/>

                </div>
            <p>
                In terms of new additions, the main addition battle-wise is the Theurgy mechanic. Based on each character's "personality",
                they will have a meter that will fill up over time (faster when certain conditions are met). The the guage is maxed,
                you can use up your meter for a Theurgy ability. Each party member has their own Theurgy skill(s), and the protagonist's Theurgy
                skills are a subset of the fusion spells from P3 FES. While I find that these make the game quite a bit easier than the original P3 FES
                (in addition to the other combat changes), I don't think it makes the game mind-numbingly easy like in Persona 5 Royal.
            </p>
            <div style = {{
                display: 'flex',
                gap: '1em',
                justifyContent: 'center',
                padding: '1em',
                flexDirection: 'column',
                alignItems: 'center'
                }}
                className = "screenShrink"
                >
                    <img src = {armageddonTheurgy} alt = "Armageddon theurgy." style = {{
                        maxWidth: '90%'
                    }}/>
                    <em>One of the protagonist's Theurgy skills that does 9999 damage to all enemies. They actually made this somewhat
                    balanced compared to the original P3 FES by putting it behind a mechanic that you need to charge over time and how 
                    using this Theurgy puts your health and SP to 1.
                    </em>
            </div>
            <p>
                With these changes, the party members have also been updated with new skills/traits. Here's a tier list of how good I think
                each of them are.
                <div style = {{
                display: 'flex',
                gap: '1em',
                justifyContent: 'center',
                padding: '1em'
                
                }}
                className = "screenShrink"
                >
                    <img src = {partyTierList} alt = "My tier list of the party members." style = {{
                        maxWidth: '90%'
                    }}/>

                </div>
                In order of the tierings:
                <ul style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}>
                    <li>
                        <em>Protagonist</em> - This is pretty self explanatory, you are the most important and versatile member in the party, as you have the ability to
                        use multiple personas and initiate all out attacks. 
                    </li>
                    <li>
                        <em>Yukari</em> - Yukari was buffed super hard from FES. Now she doesn't miss 80% of her attacks and has the highest party magic stat, 
                        so she can do quite a lot of damage. She gets a trait that reduces the cost of healing skills by 75%, so Yukari is quite busted as a heal-bot.
                        Her Theurgy skills are really solid as well, with one being severe wind damage and the other being a party-wide concentrate. She charges
                        Theurgy faster from healing, so you'll be able to pump out these skills pretty fast during boss fights. Overall, great damage and excellent support.
                    </li>
                    <li>
                        <em>
                            Aigis
                        </em> - Really solid party member. In addition to her pierce and strike damage, she has a trait that boosts her physical damage even further.
                        As well, she charges Theurgy faster simply by doing physical damage, which makes for some really good self-synergy. She also gets 
                        Matarukaja and Marakukaja, which are probably the best all-party buffs in the game. Her orgia mode mechanic from the originals was put on 
                        her Theurgy, and I found myself actually using it quite often. 
                    </li>
                    <li>
                        <em>
                            Fuuka 
                        </em> - They changed the mechanics with Fuuka quite a bit from the originals. Firstly, her full analysis costs SP now, which is a bit 
                        annoying. However, she brings a lot more versatility to the table with skills like jamming, escape, and distress. While I didn't personally
                        use these skills all that often, I found that her Theurgy was really good, as you would generally get a very good buff for your current situations
                        (SP recovery, party-wide charge/concentrate, etc). I put her in A tier as there's really no one to compare her against.
                    </li>
                    <li>
                        <em>
                            Akihiko
                        </em> - Akihiko was nerfed a fair bit from FES, as he only gets Matarunda and Masukukaja now as buffs/debuffs. However, I find that he still brings
                        a good balance of damage and support. He has good strike damage with God's Hand and electric damage with Elec Boost/Amp. Matarunda is a really good
                        debuff for fights as well, and Masukukaja isn't bad either. His Theurgy skills are pretty average, as they're just heavy/severe electric damage to enemies. 
                    </li>
                    <li>
                        <em>
                            Junpei
                        </em> - Junpei is a solid party member this game, as I found myself using him a fair bit more compared to FES. He's the strongest physical attacker in 
                        the game, and by late game he becomes a physical crit machine with the combination of his traits and items. He also has a good amount of self-synergy, as 
                        his Theurgy charges faster from crits, and everything that he has is built around getting crits. His Theurgy skills are also pure damage, but his second 
                        one also gives him a full heal, which is nice considering how physical skills use HP as a cost.
                    </li>
                    <li>
                        <em>
                            Ken
                        </em> - I actually used Ken somewhat in this game. He's mostly built around electric and light damage with some support skills mixed in. While his damage and support
                        capabilities are both relatively ok, where he really shines
                        is with his traits and Theurgy synergy. His Theurgy charges faster when he's below 1/2 SP, but his trait makes him naturally regen SP over time at a relatively 
                        fast rate. As a result, you can basically spam magic skills all you want with practically no drawback. His Theurgy skills are pretty solid, as he can either do 
                        severe light damage or basically perform the skill Salvationn, which no other party members get. The main thing holding him back is just how he doesn't do the highest 
                        magic or physical damage compared to someone like Junpei or Yukari.
                    </li>
                    <li>
                        <em>
                            Koromaru
                        </em>- The goodest boy. He's definitely better than he was in FES, as he can provide some good fire and dark damage. He even has a medium damage almighty skill 
                    for the mid game. However, with how much you spam magic sklls with Koromaru, you run out of SP really quickly, which is why I put him so low. His Theurgy skills seem 
                    pretty good (I never used him that often), as you can either do severe dark damage or give a party-wide charge. 
                    </li> 
                    <li>
                        <em>
                            Mitsuru
                        </em> - Man, she got nerfed pretty hard. While there were some annoyances in FES (Marin Karin...), she was the best late game magic damage dealer by a good margin. Now,
                        her skills and traits are built around aliments, which don't work on the majority of bosses. She gets good skills like Marukunda and Concentrate,
                        but she also lost Ice Amp, which is a really big hit to her damage. While her Theurgy skills are good damage and debuffs, I really didn't use Mitsuru close to the endgame at all.
                    </li>
                    <li>
                        <em>
                            Shinjiro
                        </em> - He dies :(
                    </li>
                </ul>
            </p>
            <h2>Story</h2>
            <p style = {{
                margin: 0
            }}>
                In terms of the story, it's pretty much the same as in the original Persona 3. This is definitely a positive, as out of the Persona games I've played (P3, P4, P5), I probably 
                enjoyed P3's story the most. However, there are some nice additions to the story. Firstly, you get more scenes with Strega, which helps make them a lot more 
                interesting compared to the original. In particular, they added a sort of "beef" between you and Takaya which persists throughout a good chunk of the game, 
                which helps with his charcter a fair amount. As well, they also added Link Episodes, which are basically stories with the male party members you can do at night.
                I also liked these a lot, as it felt like you were actually becoming friends with the other members of SEES and not just being told that by the story.
                <br/>
                While I enjoyed the additions to the story for the most part, there were some changes that I wasn't a big fan of. For instance, I didn't agree with a couple of 
                the uses of 3D models vs animated cutscenes. My main issue in this area is with stuff like the opening cutscene where your protagonist awakens his persona. They turned 
                this into a 3D scene, which I felt took a bit away from how cool it was compared to the original. They also added cutscenes for stuff like the Yakushima trip which 
                I didn't really feel were ncessary aside from giving fanservice.
                <br/>
                Despite my gripes with some of the changes they made to the presentation, the story is great. There were some scenes that were elevated significantly compared to 
                the original. In particular, Shinjiro's death was a lot sadder, and I almost cried at that part of the game. The scene of Junpei's resolution was also super well done. 
                This was in no small part due to the voice acting, which I really enjoyed for the most part.
            </p>
            <h2>
                tl;dr
            </h2>
            <p style = {{
                margin: 0
            }}>
                I really liked this remaster/rerelease! The updated gameplay, while easier than FES, was still enjoyable and not mind-numblingly easy. The visuals are 
                great, and I really enjoyed the updated menus in particular. While I didn't agree with some of the changes they made to the presentation of the story, I believe
                that the overall updates were a net positive. While I didn't 100% this game on my first playthrough, I probably won't be coming back to it for a while since I'm busy 
                with uni stuff and grinding other stuff. However, I'd really like to come back to Reload eventually to finish stuff like the Social Links and the Elizabeth fight
                in New Game+.

            </p>
            <div style = {{
                display: 'flex',
                gap: '1em',
                justifyContent: 'center',
                padding: '1em',
                alignItems: 'center',
                width: '100%'
                }}
                className = "screenShrink"
                >
                    <img src = {aigisImage} alt = "aigis" style = {{
                        maxHeight: '20em'
                    }}/>
            </div>
            

        </Section>
    },
    {
        postId: 2,
        title: "2B CS Course Review",
        description: "My thoughts on the courses I took during my 2B term.",
        content: <Section>
            <h1>
                2B CS Course Review/Term Retrospective
            </h1>
            <p style = {{
                margin: 0
            }}>This is a collection of what I thought about each of my 2B courses, as well as my overall thoughts on the term.
            </p>
            <ul style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}>
                <li>
                    <em>CS 246 - Objected Oriented Software Development</em>: OOP in C++. This course started with some of the basics of C++
                     (e.g. pointers, classes) before going into topics like design patterns and various idioms. As I had previous experience with
                     C++ through ECE 150 and ECE 250, I didn't find the majority of the content super difficult. It also helped that our instructors
                     were good and very active on Piazza. For assignments, the structure effectively forced you into writing your test cases before
                     your code via separate due dates for each component. I guess this wasn't the worst thing in the world, as it ensured that you actually
                     ensured what each question was asking before attemping an implementation. The exams were pretty fair, so the hardest part of this course
                     for me was definitely the final project. While I was reasonably confident that I could finish one of the projects by myself, I thought it
                     would be nice to find a group to distribute work and attempt to meet more people within CS. I ended up finding two really great 
                     teammates who knew what they were doing, which made working together a lot more enjoyable. I dropped the ball pretty hard in the end 
                     by forgetting to check our final presentation date, so I ended completely missing it. Thankfully, our TA was chill and didn't really mind, 
                     so our group did really well in the end.
                </li>
                <li>
                    <em>CS 245 - Logic & Computation</em>: Theoretical CS stuff. The first half of the course focused on the syntax and semantics of
                    propositional logic, while the second half covered first-order logic and various applications of it. I thought this course was 
                    fairly average in difficulty, as the assignments weren't too hard for the most part and the exams were generally fair. I wasn't really
                    a fan of how this course was taught via slides, I won't complainn much since our prof explained things pretty well.
                </li>
                <li>
                    <em>CS 241 - Foundations of Sequential Programs</em>: An introduction to the inner workings of compilation/machine code. We started with 
                    a look at machine code/assembly before moving into high-level language concepts post-midterm. The first half was relatively easy, as I
                    took ECE 222 previously which taught ARM assembly. The second half was a bit confusing at times, especially with the various parsing algorithms,
                    but thankfully the course notes were extremely helpful in walking through the concepts. The assignments came in the form of a question every
                    week or two, which made the course pretty light in terms of workload there. The projects tended to be quite a bit more time consuming, but our
                    instructors gave stepping stones for each portion of the project which turned most of them into figuring out implementation. Regardless, it was
                    still really rewarding to see the finished product at the end of the course, that being a compiler for a stripped-down version of C/C++. The 
                    exams were fair, and I found that tutorials for this course were extremely helpful for understanding the content and going through examples.
                </li>
                <li>
                    <em>STAT 230 - Probability</em>: Various probably calculation methods and distributions. This course went over some basics of probability
                    before diving into various distributions. I found this course pretty boring for the most part, as a good portion of it was taught back in
                    IB Math AA HL. The midterms were relatively fair, but the final exam was probably the most challenging one of this term. 


                </li>
                <li>
                    <em>ECON 101 - Macroeconomics</em>: An intro to econ. I would say more about what this course, but I legitimately forgot all of the content
                    from it. I took this course online and I did not put much effort into it. Usually I would 
                    spend 2-3 hours going over each weekly module before each quiz. The midterms and final were all multiple choice and open-book, 
                    so I just constantly looked at the course notes. I actually forgot the day of the final until the afternoon of the same day, 
                    so I practically spent no time studying for it.
                </li>
                <li>
                    <em>CS 136L - Tools & Techniques for Software Deveopment</em>: A lab course about various dev technologies like Bash and Git.
                    While some parts of this course were actually helpful (I actually learned how to use GDB from the console!), since I had previous
                    experience with the majority of the content from my co-op terms at BMO, it ended up being pretty free. I skipped every lab session 
                    around 3 weeks into the term and did not lose anything for it. I feel like instead of taking up a slot on the timetable, this course
                    could be made entirely online with office hours of some kind.
                </li>
            </ul>
            <p>
            This term was my first in CS. One thing I immediately noticed was that nobody gave applause after lectures, which turned out to be exclusive to 
            the ECE cohort (we love our profs 😎). I also noticed that it felt a lot more difficult to meet and make friends within the program. While this is
            probably in large part due to how I tend not to start conversations with strangers without a "good" purpose, I think being in different classes with different
            people in each was also a factor.
            </p>
            <p>
            In terms of the schoolwork, I found that while a lot less time was spent in class and labs, the assignments tended to be a lot more time consuming. Here's
            a comparison of my 2A and 2B schedules for reference:
            <div style = {{
                display: 'flex',
                gap: '2em',
                justifyContent: 'center', 
                padding: '1em'
            }}
            className = "screenShrink"
            >
                

                <img src = {engSched} alt = "2a schedule" style = {{
                    minWidth: '10em',
                    maxWidth: '90%',
                    flexBasis: '100%'
                }}/>
                <img src = {csSched} alt = "2b schedule" style = {{
                    minWidth: '10em',
                    maxWidth: '90%',
                    flexBasis: '100%'
                }}/>
            </div>
            I honestly prefer this tradeoff, as the alternative is lab hell, which is what my friends in 2B ECE went through.
            </p>
            <p>
            The final thing I think is noteworthy is the different in co-op. As I was in CS, my schedule was flexible, so I could choose whenever my co-op term 
            happened. As such, I didn't have a lot of the usual stress from constant job-searching throughout the term on WaterlooWorks. I did most of my applications
            via external job boards, which led to many rejections and probably more ghostings. This was pretty frustrating, but I guess this was a taste of how 
            searching for jobs would be like after graduation.
            </p>
            <p>
                In conclusion, while this term had a fair amount of work for my first term in CS, I definitely enjoyed it by virtue of actually liking my current
                program and being invested in a good chunk of the content. I experienced a fair amount of culture shock between programs, but if someone asked me 
                if it was too much, I would say
                <div style = {{
                display: 'flex',
                gap: '1em',
                justifyContent: 'center',
                padding: '1em'
                }}
                >
                    <img src = {gojoNo} alt = "nah, i'd adapt" style = {{
                        maxWidth: '15em'
                    }}/>

                </div>

            </p>

        </Section>
    },
    {
        postId: 1,
        title: "A guide on transferring from CE to CS at UWaterloo",
        description: "Are you a Computer Engineering student who despises hardware? Or are you an aspiring CS student who doesn't want to learn Racket? If so, this guide is for you!",
        content: 
        <Section>
            <h1>A guide on transferring from CE to CS at UWaterloo</h1>
            <img src = {engtocs} alt="epic cool visual"/>
            <h2>What is this?</h2>
            <p style = {{
                margin: 0
            }}>
                At the time of writing this, I recently transferred to CS at UWaterloo and I noticed that aside from a couple old Reddit posts, I haven't really
                seen much info out there about the entire transfer process. So, I thought I would detail my experience in transferring out from CE!
            </p>
            <h2>Who this guide is for</h2>
            <p style = {{
                margin: 0
            }}>As the title implies, this is a guide on how to transfer from Computer Engineering to Computer Science at the University of Waterloo. Thus, this guide will probably not be relevant
                to you if you are not trying to transfer and not in ECE. However, you still might find this useful you're in Engineering. In considering whether this guide is for you, think about whether it seems
                worth it to study-grind for almost the first half of your undergrad just to avoid touching hardware.
            </p>
            <h2>What you'll need</h2>
            <p style = {{
                margin: 0
            }}>
            The requirements to transfer into CS are listed <a href="https://ugradcalendar.uwaterloo.ca/page/MATH-Computer-Science-Admissions"
            className="linkhover"
            >here</a>.
            However, these are the minimum requirements to qualify for a transfer. You'll need grades a lot higher than the ones listed on the site for a chance
            of transferring, as the process is very competitive. For reference, the advisor I talked to in first year said that the practical cutoff for transferring
            was grades in the low-mid 90s. One thing to note is that Math/CS equivalent courses are more important in the consideration process, so for ECE students,
            ECE 150 and ECE 250 would be the most important courses as they turn into CS 135/136. Regardless, you should aim for high grades to maximize your chances. 
            For reference, my cummulative average across my 1A, 1B, and 2A terms was 94.88%.
            </p>
            <h2>
                General Advice
            </h2>
            <p style = {{
                margin: 0
            }}>
                In terms of general advice I would give, there are a couple major points:
                <ul style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}>
                    <li>
                        <em>Understand when you don't understand</em> - It's important to realize when you don't get something. If you don't understand something taught in
                        class, make sure you figure it out sooner or later! If you're in first year, there's a nice thing called WEEF TA office hours that you can always go to - 
                        I was a frequent visitor throughout 1A and 1B. Otherwise, since you know yourself the best, do whatever helps you learn effectively.
                    </li>
                    <li>
                        <em>Make friends</em> - One of the main features of Engineering is the cohort system and how you're in the same class with people for multiple terms. 
                        Take advantage of this while you're still in the faculty to make friends! Even though I'm in CS now, the majority of my friends are still from ECE.
                    </li>
                    <li>
                        <em>Know when to take a break</em> - Constantly grinding away in Engineering gets pretty tedious, and you should relax sometimes
                        to avoid burning out.
                    </li>
                    <li>
                        <em>Don't skip lectures</em> - While some people can probably learn an entire course from a textbook, I would generally recommend attending classes if
                        the prof is actually teaching. For tutorials, I 
                        would go if you find them helpful - for example, I skipped all my ECE 140 tutorials where there wasn't an assesment during the tutorial.
                    </li>
                </ul>
                This advice is pretty obvious, but important!
            </p>
            <h2>
                Term-Specific Advice
            </h2>
            <p style = {{
                margin: 0
            }}>
                This section will go over my experience with the courses for each term in Computer Engineering I went through before transferring, as well as
                advice for each term. You'll notice that a lot of the advice for each course will just boil down to doing practice problems and seeking help 
                where necessary.
                <Section style = {{
                    width: '100%',
                    padding: '1em'
                }}>
                    <strong>1A</strong>
                    <p style = {{
                        margin: 0,
                        width: '100%'
                    }}>The start of your undergrad career! This term has ECE 150 which is somewhat important, but I've read online that ECE 250 matters more.
                    Whether or not this is actually true I don't know, but regardless you should still try to do well. This term was also mostly online for me,
                    so your experience may vary.
                    <ul style = {{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '.5em',
                    }}>
                        <li>
                            <em>
                                ECE 150
                            </em> - Intro to programming in C++. Covers basic C++ syntax up to pointers and some OOP. Our assesments for this course were
                            in the form of projects, which were pretty easy. I didn't do super well on the exams for this one, but to be honest they were
                            pretty fair.
                        </li>
                        <li>
                            <em>
                                ECE 105 
                            </em> - Basically just physics (kinematics, dynamics, etc). I hated this course - concepts weren't explained super well and assesments were pretty difficult.
                                To do well here, I think the best thing to do is just grind practice problems to understand the content.
                        </li>
                        <li>
                            <em>
                                MATH 117 
                            </em> - Calc 1 for Eng. If you took IB Math or something similar in high school, this course should be pretty straightforward.
                                As you would expect, just do homework whenever you need extra practice with something.
                        </li>
                        <li>
                            <em>
                                MATH 115
                            </em> - Linear Algebra. While this course felt harder than MATH 117, I had a bit of experience with Linear Algebra from high
                            school so it wasn't the hardest thing in the world. Just do your homework and practice problems and you should be fine.
                        </li>
                        <li>
                            <em>
                                ECE 190
                            </em> - Engineering Profession and Practice. Pretty free, just participate in class activities and do your group work (assuming
                            your assesments are in that form). No exams, which is pretty nice!
                        </li>
                        <li>
                            <em>
                                ECE 198
                            </em> - Project Studio. You basically create an embedded project over the course of the term with a STM32 board. I did not like
                            this course very much, as they expect you to cook up an embedded C project without teaching you any of it beforehand. While I got
                            hard carried by my partner here, I think it is doable to figure out everything necessary for this course.
                        </li>
                        <li>
                            <em>
                                ARTS 190
                            </em> - Something something communication. I don't remember much from this course, but in our offering we had to go to this IEEE event thing
                            and take notes on it to make a paper at the end. I guess just participate in class and put a decent amount of effort into assignments?
                        </li>
                    </ul>
                    For 1A in particular, you'll have your first experience with university exams. In my experience, engineering courses tend to be very "grindable", i.e.
                    you can prepare for exams fairly well just by doing practice problems. To do well on exams, I always found that doing as many practice problems as possible 
                    was effective. Problems included the ones given by instructors, past exams, online questions, etc. You'll actually find this is a pattern for a lot of your courses.
                    </p>
                </Section>
                <Section style = {{width: '100%', padding: '1em'}}>
                    <strong>1B</strong>
                    <p style = {{
                        margin: 0,
                        width: '100%'
                    }}>
                        This term is quite a step up from 1A, as you have 6 courses that each have exams. If you're in stream 4, you also won't have a reading
                        week for a break. As such, it's extra important this term that you stay on top of things. While neither of the key course to do well on
                        (ECE 150, ECE 250) are in this term (at least for now), you should still try to do well.
                        <ul style = {{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '.5em',
                        }}>
                            <li>
                                <em>
                                    ECE 106
                                </em> - Electricity and Magnetism. This is still physics, but unlike ECE 105, I felt that the instructors put a lot more time
                                into making sure you understood the content. For our cohort, they actually set up a discord server where you could set up tickets for
                                instructors to personally answer questions. As well, our prof had office hours which were basically big Q&A sessions which I found super helpful -
                                be sure to go to these. While this course is also very diffuclt (arguably more so than ECE 105), it's definitely possible to do well with lots of practice.
                            </li>
                            <li>
                                <em>
                                    ECE 124
                                </em> - Digital Circuits. Probably the second hardest course of the term. Again, if you don't understand a particular concept for this course,
                                    do practice problems from your textbook/online. As well, while the labs for this course were pretty time consuming, if you understand the stuff 
                                    taught in class, they shouldn't be that difficult.
                            </li>
                            <li>
                                <em>
                                    ECE 140
                                </em> - Circuits. I got hard carried by my graphing calculator which could solve matrices, but if you're not lucky enough to have one from high school,
                                you should make sure you're comfortable with solving systems of linear equations and complex numbers. I also remember there being lots of helpful YouTube 
                                videos for the topics taught in this class. 
                            </li>
                            <li>
                                <em>
                                    MATH 119
                                </em> - Calc 2 for Eng. This course builds off of MATH 119, so make sure you have good fundamentals with derivatives/integrals. This course wasn't super difficult
                                (our class midterm mean was 81.6%), but as with all the other courses, make sure you stay on top of things.
                            </li>
                            <li>
                                <em>
                                    ECE 108
                                </em> - Discrete Math. Pretty average course, just do practice problems as necessary to do well.
                            </li>
                            <li>
                                <em>
                                    ECE 192
                                </em> - Engineering Economics. Very free, just do the tutorial problems as practice and read off the slides.
                            </li>

                        </ul>

                    </p>
                </Section>
                <Section style = {{width: '100%', padding: '1em'}}>
                    <strong>2A</strong>
                    <p style = {{
                        margin: 0
                    }}>
                        At this point, if you're still aiming for a transfer, you'll apply for it this term. The advice here is quite similar to 
                        the advice from before.
                        <ul style = {{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '.5em',
                            width: '100%'
                        }}
                        >
                            <li>
                                <em>
                                    ECE 250
                                </em> - Data Structures & Algos. This course transfers over as CS 136, so it's important to do well here. In terms of practice,
                                doing the tutorial problems and additional questions online was helpful for me. As well, doing Leetcode was good for practicing
                                the problem-solving aspect of this course. The main thing you should understand for exams is running the various algorithms and
                                knowing what situations they're suited for. The "labs" for this course were just coding assignments which were easy-medium level,
                                so just make sure to your code thoroughly there.
                            </li>
                            <li>
                                <em>
                                    ECE 222
                                </em> - Digitial Computers. This course transfers over as CS 251, so it might be important for transferring. For the Assembly
                                programming portion of this course, make sure you know how to implement the concepts they go over in class. For example, one of
                                the questions on our midterm involved writing Assembly for polling I/O. For the other half of this course, I think flashcards are 
                                helpful, as a lot of the concepts there are just memorization. The labs for this course mainly involve the Assembly portion of this 
                                course, so as long as you understand that you should be good.
                            </li>
                            <li>
                                <em>
                                ECE 240
                                </em> - Circuits. I hated this course, but I think the best way to study for it is just to grind practice problems and pray that you 
                                get a good prof. I also found the labs to be pretty stressful, as you had to do all the activities mentioned in the manual without much 
                                debugging to complete.
                            </li>
                            <li>
                                <em>
                                    ECE 205
                                </em> - Differential Equations. I didn't find this course to be super hard, just do as many practice problems as you deem necessary to
                                understand the concepts.
                            </li>
                            <li>
                                <em>
                                    ECE 204
                                </em> - Numerical Methods. Pray you get a good prof, and practice by doing questions from past exams. The labs for this course were 
                                pretty straightforward, but they may involve MatLab and C.
                            </li>
                            <li>
                                <em>
                                    ECE 109
                                </em> - Materials Chem. Pretty easy, just go through assignments and do tutorial problems and you should be good.
                            </li>
                        </ul>

                    </p>
                </Section>
            </p>
            <h2>Applying for the Transfer</h2>
            <p style = {{
                margin: 0
            }}>
                During the 2A term (or whatever term you're eligible to apply), you will need to fill out two forms 
                <a href="https://cs.uwaterloo.ca/current-undergraduate-students/cstransfer#TransferNonMath" className = "linkhover"> here</a>.
                These will usually open a couple weeks into the term. They're not first-come first-serve, so as long as you fill them out before 
                the forms close, you will be fine.
                <ul
                style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}
                >
                    <li>
                        <em>Transfer from outside of Math to CS</em>
                        - This is the form that requires you to write a bit on why you transferred. Just write something sensible here.
                    </li> 
                    <li>
                        <em>Transfer from outside of Math to Math</em>
                        - This form just requires you to fill out some information about what you want to transfer into. Make sure you pick CS co-op if
                        you want to stay in co-op.
                    </li>
                </ul>
            </p>
            <h2>What happens after</h2>
            <p style = {{
                margin: 0
            }}>
                Assuming you have finished all your exams and you submitted the necessary forms, all you can do is wait. You should get your results
                fairly early into the preceding term. For reference, I got my results a week into my work term. Assuming you were accepeted, congrats! You never 
                have to worry about what a MOSFET or nodal analysis is for your undergrad! From here, you'll probably need to talk to some math/CS advisors 
                for next steps (I don't remember the specifics of everyone I talked to), but the hard part of transferring is over. Next, you'll need to be 
                overriden into MATH 135 to open up a bunch of courses in the Faculty of Math. I won't be including any advice for MATH 135 here because at this point,
                you should have the study habits necessary to do well or you won't care about the result since you're already in.

            </p>
            <p style = {{
            }}>
                Assuming you were in ECE, your courses will transfer over as follows:
                <ul
                style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}
                >
                    <li>
                        ECE 105 {'->'} PHYS 121
                    </li>
                    <li>ECE 150 {'->'} CS 135</li>
                    <li>MATH 115 {'->'} MATH 136</li>
                    <li>MATH 117 {'->'} MATH 137</li>
                    <li>ECE 106 {'->'} PHYS 122</li>
                    <li>MATH 119 {'->'} MATH 138</li>
                    <li>ECE 205 {'->'} AMATH 250</li>
                    <li>ECE 222 {'->'} CS 251</li>
                    <li>ECE 250 {'->'} CS 136</li>
                    <li>ARTS 190 {'->'} List I Communication</li>
                </ul>
                As well, some of your PD courses will transfer over:
                <ul
                style = {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5em'
                }}
                >
                    <li>PD1 - equivalent from ENG courses</li>
                    <li>PD11 - equivalent from ENG courses</li>
                    <li>One PD elective</li>
                </ul>
                All your other courses will transfer over as electives, so to complete your undergrad, you will mostly just need to complete the core 
                CS/MATH/STAT classes. From here, it's up to you choose your courses, as unlike in Engineering, you have to pick your courses each term. However, for your
                first term out of ECE, I would recommend choosing CS 246, CS 245, and STAT 230, as these are the core 2A CS courses you will need to catch up on.
            </p>
            <h2>Conclusion</h2>
            <p style = {{
                margin: 0
            }}>
                While transferring from ECE to CS is definitely hard, it's definitely doable. In terms of a tl;dr for transferring, you should keep good study habits,
                stay on top of work, and touch grass where necessary. Feel free to reach out if you want extra detail added or want more clarification. Good luck!

            </p>
        </Section>
    }
]

export default posts;
