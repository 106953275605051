import Section from "../components/Section"
import lodestoneImage from '../resources/lodestonelogo.png'
import recipeImage from '../resources/recipe.png';
import chatroomsImage from '../resources/chatrooms.png';
import hadesImage from '../resources/hades.png';

interface Project {
    name: string,
    imageSrc: string,
    description: string,
    link: string
}

const projects: Array<Project> = [
    {
        name: "Lodestone",
        imageSrc: lodestoneImage,
        description: "An open source server hosting tool for Minecraft and other multiplayers. As a developer on the team, I mainly work with React.js, TypeScript, and Rust.",
        link: "https://github.com/Lodestone-Team/lodestone"
    },
    {
        name: "Recipe Architech",
        imageSrc: recipeImage,
        description: "An application I worked on during Hack the North 2023 to generate a set of recipes from a grocery flyer! I worked with a variety of technologies including Flask, SQLAlchemy, CockroachDB, and TaiPy.",
        link: "https://youtu.be/kPrfLD_TVVU?feature=shared"
    },
    {
        name: "Chatrooms",
        imageSrc: chatroomsImage,
        description: "A web app I created using React.js and Firebase to for real-time chatting within rooms.",
        link: "https://github.com/jhuang38/chatrooms"
    },
    {
        name: "VC Hades",
        imageSrc: hadesImage,
        description: "A mini-project/script using various Python speech recognition libraries that I worked on over a weekend to implement voice commands for playing the game Hades.",
        link: "https://github.com/jhuang38/vc-hades"
    }
]

export default function ProjectsPage() {
    let rowNum = 0;
    return (
        <Section>
        <div>
            <h1>Projects</h1>
            <p>Some of the projects I've worked on! The rest of my projects can be found on my GitHub.</p>
            <div style = {{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                rowGap: '1em',
                columnGap: '4em'
            }}>
            {
                projects.map((project, i) => {
                    if (i % 2 === 0) {
                        rowNum++;
                    }
                    return (
                        <>
                            <div style = {{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1em',
                                backgroundColor: '#2f302f',
                                borderRadius: '1.5em',
                                padding: '1.5em',
                                width: '100%',
                                gridRowStart: rowNum,
                                gridRowEnd: rowNum,
                                gridColumnStart: i % 2 + 1,
                                gridColumnEnd: i % 2 + 1,
                            }}
                            className = "buttonAnimation"
                            onClick={() => {
                                window.open(project.link);
                            }}
                            >
                                <h3 style = {{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: '1.25em'
                                }}>{project.name}</h3>
                                <img src = {project.imageSrc} style = {{
                                    maxHeight: '20em',
                                    maxWidth: '100%',
                                    borderRadius: '0.5em'
                                }}
                                alt={project.name}
                                />
                                <p style = {{
                                    position: 'relative',
                                    bottom: 0
                                }}>{project.description}</p>
                            </div>
                            <br/>
                        </>
                        
                    )
                })
            }
            </div>
        </div>   
        </Section>
    )
}