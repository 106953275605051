import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/global.css"

import { RouterProvider, Navigate, createHashRouter, createBrowserRouter } from 'react-router-dom';
import Root from './routes/Root';
import About from './routes/About';
import BlogHome from './routes/BlogHome';
import ExperiencePage from './routes/ExperiencePage';
import SkillsPage from './routes/SkillsPage';
import ProjectsPage from './routes/ProjectsPage';
import BlogPostPage from './routes/BlogPostPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <Navigate to={"about"}/>,
    children: [
      {
        index: true,
        element: <Navigate to="/about" replace/>
      },
      {
        path: "about",
        element: <About/>
      },
      {
        path: "experience",
        element: <ExperiencePage/>
      },
      {
        path: "skills",
        element: <SkillsPage/>
      },
      {
        path: "projects",
        element: <ProjectsPage/>
      },
      {
        path: "blog",
        element: <BlogHome/>,
      },
      {
        path: "blog/:blogid",
        element: <BlogPostPage/>
      }
    ]
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

